<template>
  <div class="body">
    <div class="banxin">
      <div class="tt df aic">
        <div class="suxian"></div>
        企业资质
      </div>
      <div class="bghui">
        <div class="OrderDetail_mid">
          <div class="list">
            <div class="li fs14">
              <div class="fhui">企业名称</div>
              <div class="fs14">成都云集药业有限公司</div>
            </div>
            <div class="li fs14">
              <div class="fhui">证件类型</div>
              <div>三证合一</div>
            </div>
            <div class="li fs14">
              <div class="fhui">印章名称</div>
              <div>成都市云集药业有限公司 <span class="hui">（<span style="color:#E33233">*</span>印章名称须与营业执照上的药店名称一致）</span> </div>
            </div>
          </div>
          <div class="seacend list">
            <div class="li fs14">
              <div class="fhui">企业类型</div>
              <div class="fs14">药品经营企业</div>
            </div>
            <div class="li fs14">
              <div class="fhui">印章类型</div>
              <div class="fs14">
                公章
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tserch df aic">
        <div class="search">
          <el-input class="input" type="text" placeholder="请输入资质名称" v-model.trim="serchKey" clearable />
          <div class="btn cursor">搜索</div>
        </div>
      </div>

      <div class="table">
        <el-table :header-cell-style="{ background: '#F8F8F8' }" ref="singleTable" :data="searchValue" highlight-current-row style="width: 100%">
          <el-table-column property="name" label="资质名称" align="left" width="auto"></el-table-column>
          <el-table-column property="xx" align="center" label="效期" width="auto">
            <template>长期</template>
          </el-table-column>
          <el-table-column property="xx" align="center" label="上传时间" width="auto">
              <template>--</template>
          </el-table-column>
          <el-table-column property="xx" align="center" label="上传者" width="auto">
              <template>--</template>
          </el-table-column>
          <el-table-column property="xx" align="right" label="操作" width="150">
            <template slot-scope="scope">

              <div class="cup" @click="checkout(scope.row)">查看</div>
              <viewer v-show="false" ref="viewer">
                <img v-for="(decImg, index) in scope.row.img" :key="index" :src="decImg" style="width:120px;height:120px">
              </viewer>
            </template>
          </el-table-column>
        </el-table>

        <div v-if="this.LoanInfo.state == '1'" class="footerConfirm">
          <el-button @click="goRepayment" type="success">发起还款信息</el-button>

          <el-button @click="confrimPay">确认还款信息</el-button>
        </div>
      </div>
    </div>

  </div>
</template>
 
<script>
export default {
  data() {
    return {
      serchKey: undefined,
      LoanInfo: {},
      tableData: [
        {
          name: "2021年度报告",
          img: [require("@/assets/images/qualifications/2021年度报告.jpg")],
        },
        {
          name: "存款账户",
          img: [require("@/assets/images/qualifications/存款账户.jpg")],
        },
        {
          name: "二类器械",
          img: [require("@/assets/images/qualifications/二类器械.jpg")],
        },
        {
          name: "发票样本",
          img: [require("@/assets/images/qualifications/发票样本.jpg")],
        },
        {
          name: "开票",
          img: [require("@/assets/images/qualifications/开票.jpg")],
        },
        {
          name: "年度报告",
          img: [require("@/assets/images/qualifications/年度报告.jpg")],
        },
        {
          name: "食品经营许可证",
          img: [require("@/assets/images/qualifications/食品经营许可证.jpg")],
        },
        {
          name: "市场监督管理局公示查询平台",
          img: [require("@/assets/images/qualifications/市场监督管理局公示查询平台.jpg")],
        },
        {
          name: "随货同行单",
          img: [require("@/assets/images/qualifications/随货同行单.jpg")],
        },
        {
          name: "网络交易备案",
          img: [require("@/assets/images/qualifications/网络交易备案.jpg")],
        },
        {
          name: "现代物流系统检查评估结果",
          img: [require("@/assets/images/qualifications/现代物流系统检查评估结果.jpg")],
        },
        {
          name: "许可证变更记录",
          img: [require("@/assets/images/qualifications/许可证变更记录.jpg")],
        },
        {
          name: "许可证副本",
          img: [require("@/assets/images/qualifications/许可证副本.jpg")],
        },
        {
          name: "药品经营许可证",
          img: [require("@/assets/images/qualifications/药品经营许可证.jpg")],
        },
        {
          name: "一般纳税",
          img: [require("@/assets/images/qualifications/一般纳税.jpg")],
        },
        {
          name: "医疗器械许可证",
          img: [require("@/assets/images/qualifications/医疗器械许可证.jpg")],
        },
        {
          name: "营业执照",
          img: [require("@/assets/images/qualifications/营业执照.jpg")],
        },
        {
          name: "章",
          img: [require("@/assets/images/qualifications/章.jpg")],
        },
        {
          name: "质量保证协议书",
          img: [require("@/assets/images/qualifications/质量保证协议书.jpg")],
        },
        {
          name: "质量体系表",
          img: [require("@/assets/images/qualifications/质量体系表.jpg")],
        },
      ],
    };
  },
  computed: {
    searchValue() {
      return this.tableData.filter((value) => {
        return this.serchKey ? value.name.match(this.serchKey) : value;
      });
    },
  },
  watch: {},
  beforeDestroy() {},
  created() {},

  methods: {
    checkout(item) {
      this.$viewerApi({
        images: item.img,
      });
    },
  },
};
</script>
 
<style lang = "less" scoped>
@import "../../assets/base.less";
::v-deep {
  .el-input__suffix {
    display: flex;
    align-items: center;
  }
  .el-input__inner {
    height: 30px;
    line-height: 30px;
    border: none !important;
  }
  .el-dialog__body {
    padding: 0;
  }
}
.cup:hover {
  color: @green;
}
.info {
  display: flex;
  align-items: center;
  .icon {
    font-size: 19px;
    color: #faad14;
    margin-right: 10px;
  }
}
.toast {
  margin-top: 15px;
  background-color: #fffae5;
  padding: 30px 15px;
  display: flex;
  line-height: 1.5;
}

.banxin {
  padding-top: 60px;
  padding-bottom: 88px;
}
.fhui {
  color: #707070;
}
.rt {
  color: #333333;
}

.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  width: 274px;
  border: 1px solid @green;
  .input {
    width: 210px;
  }
  .btn {
    width: 64px;
    background-color: @green;
    color: #ffffff;
    line-height: 32px;
    text-align: center;
    user-select: none;
  }
}
.prepayment {
  .li {
    display: flex;
    align-items: center;
    .txt {
      text-align: right;
      width: 30%;
      margin-right: 10px;
    }
  }
}
.hui {
  font-size: 14px;
  font-family: 'regular';
  font-weight: 400;
  color: #9e9fab;
}
.red {
  color: #e33233;
}
.footerConfirm {
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  padding-bottom: 52px;
  padding-right: 30px;
  padding-top: 30px;
  display: flex;
  justify-content: flex-end;
}
.suxian {
  margin-right: 8px;
  font-size: 18px;
  font-weight: 600;
  color: #333333;
}
.sureli {
  padding: 0 30px;
  display: flex;
  height: 46px;
  line-height: 46px;
  justify-content: space-between;
  border-bottom: 1px dashed @hui;
  background: linear-gradient(white, white) padding-box,
    repeating-linear-gradient(-45deg, #ccc 0, #ccc 0.5em, white 0, white 0.75em);
}
.OrderDetail_mid {
  justify-content: space-between;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 30px;
  background: #f8f8f8;
  height: 168px;
  width: 100%;
  .list {
    width: 70%;
  }
  .seacend {
    flex: 1;
  }
  .li {
    display: flex;
    margin-bottom: 20px;
    .fhui {
      margin-right: 25px;
    }
  }
  .h1 {
    margin-bottom: 26px;
    font-size: 14px;
    font-weight: bold;
  }
}
.body {
  background-color: @body;
}

.bghui {
  height: 150px;
  box-sizing: border-box;
  padding: 0 60px 30px 30px;
  background-color: #fff;
  box-sizing: border-box;
  color: #333333;
}
.tserch {
  background-color: #fff;
  padding: 30px;
  margin-bottom: 0;
}
.tt {
  font-size: 18px;
  font-family: 'medium';
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  padding: 30px;
  font-weight: 600;
  margin-bottom: 0;
}
.table {
  padding: 0 60px 30px 30px;
  background-color: #ffffff;
}
</style>