<template>
  <div class="MyFavorites p32">
    <div class="hed">
      <div class="tt df">
        <div class="suxian"></div>
        我的收藏
      </div>
      <div class="ipt">
        <el-input
          placeholder="请输入内容"
          v-model="serchTxt"
          class="input-with-select"
        >
          <el-button
            type="success"
            slot="append"
            icon="el-icon-search"
          ></el-button>
        </el-input>
      </div>
    </div>
    <div class="list">
      <el-table
        :header-cell-style="{ background: '#F8F8F8' }"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="全选" min-width="400">
          <template>
            <div class="cardlist">
              <div class="lii">
                <div class="card">
                  <!-- <img style="width: 86px; height: 60px" src="@/assets/images/home/9999999.png" alt /> -->
                </div>
                <div class="goodsDetail">
                  <div class="h1">云南白药云南白药云南白药</div>
                  <div class="info">厂家： 云南白药</div>
                  <div class="info">件装量： 100盒/件</div>
                  <div class="info">效期： 24个月</div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="规格"
          width="auto"
        ></el-table-column>
        <el-table-column
          prop="address"
          label="单价(元)"
          width="auto"
        ></el-table-column>
        <el-table-column
          prop="address"
          label="数量"
          width="auto"
        ></el-table-column>
        <el-table-column
          width="auto"
          prop="address"
          label="总计(元)"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template>
            <div style="  text-align: right;margin-right:20px" class="gr cup">
              加入购物车
            </div>
            <el-button type="text" size="small">取消收藏</el-button>
            <el-button type="text" size="small">商品详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="df" style="margin-top: 20px">
        <el-checkbox @change="toggleSelection(tableData)">全选</el-checkbox>
        <div style="margin-left:20px;color:#707070" class="quxiao cup fs14">
          批量取消特别关注
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      serchTxt: "",
      tableData: [
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-08",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-06",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-07",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      multipleSelection: [],
    };
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/base.less";
.hed {
  justify-content: space-between;
  align-items: center;
  display: flex;
  .tt {
    font-weight: 600;
  }
}
.df {
  align-items: center;
}
.cardlist {
  display: flex;
  box-sizing: border-box;
  font-size: 12px;
  .lii {
    display: flex;
    .card {
      text-align: center;
      border: 1px solid @hui;
      width: 100px;
      height: 100px;
      box-sizing: border-box;
      padding: 20px 8px;
    }
    .goodsDetail {
      flex: 1;
      margin-left: 16px;
      line-height: 1.5;
      .h1 {
        font-size: 16px;
        font-weight: 500;
      }
      .info {
        font-size: 12px;
        margin-bottom: 4px;
        color: #999999;
      }
    }
  }
}
</style>
